// Import the 'createSlice' function from Redux Toolkit for defining state slices with reducers
import { createSlice } from '@reduxjs/toolkit';

/**
 * @module messagesSlice
 * @description Redux slice managing the messages state in the Redux store.
 * The messagesSlice handles actions related to messages, both public and private, including storing messages,
 * updating message state during fetch operations, and handling the reception of new messages.
 */

// Define the initial state for the message management slice
const initialState = {
    publicMessages: [], // Array of public message objects retrieved from the server
    privateMessages: [], // Array of private message objects retrieved from the server
    loading: false, // Flag to indicate loading state during async operations
    error: null, // Flag to store error messages in case of a failed async operation
};

// Create a slice for messages with reducers and actions automatically generated
const messagesSlice = createSlice({
    name: 'messages', // Unique identifier for this slice
    initialState, // Set the initial state for this slice
    reducers: {
        // Initiate fetching messages, set loading to true
        fetchMessagesStart(state) {
            state.loading = true; // Set loading true when fetching begins
        },

        // Handle successfully fetched public messages, update state
        fetchPublicMessagesSuccess(state, action) {
            state.publicMessages = action.payload; // Fetch and store public messages from the server into the state
            state.loading = false; // Set loading false on fetch success
        },

        // Handle successfully fetched private messages, update state
        fetchPrivateMessagesSuccess(state, action) {
            state.privateMessages = action.payload; // Fetch and store private messages from the server into the state
            state.loading = false; // Set loading false on fetch success
        },

        // Handle fetch errors, update state with error info
        fetchMessagesFailure(state, action) {
            state.error = action.payload; // Store error message on fetch failure
            state.loading = false; // Reset loading state
        },

        // Add a new public message to the state
        publicMessageReceived(state, action) {
            state.publicMessages.push(action.payload); // Add a new public message to the state
        },

        // Add a new private message to the state
        privateMessageReceived(state, action) {
            state.privateMessages.push(action.payload); // Add a new private message to the state
        },
    },
});

// Export the generated action creators for use in components
export const {
    fetchMessagesStart,
    fetchPublicMessagesSuccess,
    fetchPrivateMessagesSuccess,
    fetchMessagesFailure,
    publicMessageReceived,
    privateMessageReceived,
} = messagesSlice.actions;

// Export 'messagesSlice' reducer for accessibility throughout the application
export default messagesSlice.reducer;
