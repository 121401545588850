/**
 * ChatPrivate Component
 *
 * This component facilitates private chat functionality in the application.
 * It allows users to select a chat recipient from a list, displays the chat history with that user, and provides an interface to send new messages.
 * It interacts with Redux for state management, uses services for data fetching, and renders child components like ChatPrivateUsersList.
 *
 * @returns {JSX.Element} The complete private chat interface including user selection and message display.
 * @type {React.FunctionComponent}
 */

// Import 'useEffect' for lifecycle management and 'useState' for state management within the component
import { useState, useEffect } from 'react';

// Import hooks from 'react-redux' to interact with the Redux state
import { useSelector, useDispatch } from 'react-redux';

// Import a function to send messages over the socket
import { sendSocketMessage } from '../../services/socketService';

// Import action creators for message handling
import {
    fetchMessagesStart, // Action to indicate the start of a fetch operation
    fetchPrivateMessagesSuccess, // Action to handle successful retrieval of private messages
    fetchMessagesFailure, // Action to handle errors during the fetch of private messages
} from '../../store/messagesSlice';

// Import service to interact with messages on the backend
import MessagesService from '../../services/messagesService';

// Import the user list component for private chat
import ChatPrivateUsersList from '../../components/ChatPrivateUsersList/ChatPrivateUsersList';

// Import selectors to retrieve state from the Redux store
import {
    privateMessagesSelector, // Import the selector for retrieving private messages from the Redux store
    loadingSelector, // Import the selector for checking the loading status in the messages state
    errorSelector, // Import the selector for accessing any error messages from the messages state
    userSelector, // Import the selector for obtaining the current user's information from the user state
} from '../../store/selectors';

// Import FaUserCircle icon from react-icons/fa to use as a default avatar in the user interface
import { FaUserCircle } from 'react-icons/fa';

// Import Moment.js for date and time manipulation and formatting
import moment from 'moment';

const ChatPrivate = () => {
    // Initialize the dispatch function to enable the dispatching of actions to the Redux store
    const dispatch = useDispatch();

    // Retrieve private messages from the Redux store, defaulting to an empty array if not present
    const privateMessages = useSelector(privateMessagesSelector).data || [];

    // Retrieve the loading state from the Redux store to indicate if data is currently being fetched
    const loading = useSelector(loadingSelector);

    // Retrieve any potential error message from the Redux store related to fetching data
    const error = useSelector(errorSelector);

    // Retrieve the current user's data from the Redux store
    const user = useSelector(userSelector);

    // State for storing the input value of the message text field
    const [input, setInput] = useState('');

    // State for storing the ID of the currently selected user for messaging
    const [selectedUserId, setSelectedUserId] = useState(null);

    // State for storing the name of the currently selected user for display
    const [selectedUserName, setSelectedUserName] = useState('');

    // State for storing the image URL of the selected user for display
    const [selectedUserImage, setSelectedUserImage] = useState('');

    // Callback function that sets the currently selected user's details into the component's state
    const onSelectUser = (userId, userName, userImage) => {
        setSelectedUserId(userId); // Set the selected user's ID to state
        setSelectedUserName(userName); // Update the selected user's name in state
        setSelectedUserImage(userImage); // Set the selected user's image
    };

    // Fetch messages when a user is selected
    useEffect(() => {
        // Define an asynchronous function to fetch private messages
        const fetchMessages = async () => {
            // Check if a user ID is selected to fetch messages for
            if (selectedUserId) {
                // Dispatch an action to update the state to reflect that message fetching has started
                dispatch(fetchMessagesStart());
                try {
                    // Await the response from fetching private messages for the selected user
                    const response =
                        await MessagesService.fetchPrivateMessages(
                            selectedUserId,
                        );
                    // Dispatch an action to store the fetched messages in the state
                    dispatch(fetchPrivateMessagesSuccess(response.data));
                } catch (error) {
                    // Dispatch an action to update the state with the error information
                    dispatch(fetchMessagesFailure(error.toString()));
                }
            }
        };
        // Call the fetchMessages function to execute the fetching process
        fetchMessages();
        // An array of dependencies that triggers re-execution of this effect when they change
    }, [selectedUserId, dispatch]);

    // Function to handle message sending
    const sendMessage = () => {
        // Check if the input is not empty (after trimming whitespace)
        if (input.trim()) {
            // Send the message over the socket with 'input', specifying it as not public ('false'), and providing the selected user ID
            sendSocketMessage(input, false, selectedUserId);
            // Reset the input field to an empty string after sending the message
            setInput('');
        }
    };

    // Handler for the Enter key in the input field
    const handleKeyDown = (e) => {
        // Check if the 'Enter' key was pressed during the event
        if (e.key === 'Enter') {
            // Prevent the default action to avoid submitting the form traditionally
            e.preventDefault();
            // Call the sendMessage function to handle sending the message
            sendMessage();
        }
    };

    return (
        // Main container that holds both user list and chat display areas
        <div className="flex h-full">
            {/* Container for the list of users */}
            <div className="w-1/3 overflow-auto border-r border-gray-300">
                <ChatPrivateUsersList
                    onSelectUser={onSelectUser}
                    selectedUserId={selectedUserId}
                />
            </div>
            {/* Container for the chat area */}
            <div className="flex-1 p-4">
                {/* Conditionally display the chat interface if a user is selected */}
                {selectedUserId ? (
                    <>
                        {/* Displays the name of the user currently being chatted with in a bold large font */}
                        <h2 className="text-lg font-bold">
                            Private Chat with {selectedUserName}
                        </h2>
                        {/* Container for messages with a scrollbar if content overflows */}
                        <div className="mb-4 flex h-[90%] flex-col overflow-auto">
                            {/* Show a loading message while messages are being fetched */}
                            {loading ? (
                                <p>Loading messages...</p>
                            ) : error ? (
                                // Display an error if messages cannot be fetched
                                <p>Error fetching messages: {error}</p>
                            ) : (
                                // Display messages if there are any, otherwise show a message indicating no messages are available
                                <>
                                    {privateMessages.length > 0 ? (
                                        privateMessages.map((msg, index) => (
                                            // Individual message container
                                            <div
                                                key={index}
                                                className={`mb-2 flex items-center ${
                                                    msg.senderId === user._id
                                                        ? 'justify-end'
                                                        : 'justify-start'
                                                }`}
                                            >
                                                {/* Message text and timestamp display */}
                                                <div
                                                    className={`flex flex-col ${
                                                        msg.senderId ===
                                                        user._id
                                                            ? 'items-end'
                                                            : 'items-start'
                                                    }`}
                                                >
                                                    <span className="text-sm text-gray-600">
                                                        {msg.message}
                                                    </span>
                                                    <span className="text-xs text-gray-500">
                                                        {moment(
                                                            msg.createdAt,
                                                        ).format(
                                                            'HH:mm DD.MM.YYYY',
                                                        )}
                                                    </span>
                                                </div>
                                                {/* Show user avatar or a default icon if no image is available */}
                                                {selectedUserImage ? (
                                                    <img
                                                        src={selectedUserImage}
                                                        alt="avatar"
                                                        className="ml-2 h-10 w-10 rounded-full"
                                                    />
                                                ) : (
                                                    <FaUserCircle className="ml-2 h-10 w-10 text-gray-400" />
                                                )}
                                            </div>
                                        ))
                                    ) : (
                                        <p>No messages with this user.</p>
                                    )}
                                </>
                            )}
                        </div>
                    </>
                ) : (
                    <p>Please select a user from the list to start chatting.</p>
                )}
                {/* Input area to type new messages with a send button */}
                <div className="mt-auto">
                    <input
                        type="text"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        onKeyDown={handleKeyDown}
                        className="w-full border-2 border-gray-200 p-2"
                        placeholder="Type your message..."
                    />
                    <button
                        onClick={sendMessage}
                        className="mt-2 w-full bg-blue-500 p-2 text-white"
                    >
                        Send
                    </button>
                </div>
            </div>
        </div>
    );
};

// Export 'ChatPrivate' component for accessibility throughout the application
export default ChatPrivate;
