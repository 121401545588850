/**
 * ChatPrivateUsersList Component
 *
 * Renders a list of users for private chat interactions.
 * This component fetches a list of users from the backend and displays them.
 * It allows users to be selected for private chatting.
 * It uses the UserService to fetch users and handles errors with react-toastify notification.
 *
 * @param {Object} props - Component props.
 * @param {Function} props.onSelectUser - Callback to handle when a user is selected.
 * @param {string} props.selectedUserId - ID of the currently selected user.
 * @returns {JSX.Element} Rendered component for displaying a list of chat users.
 * @type {React.FunctionComponent}
 */

// Import 'useEffect' for lifecycle management and 'useState' for state management within the component
import { useEffect, useState } from 'react';

// Import 'UserService' to interact with user-related API endpoints
import UserService from '../../services/userService';

// Import toast for displaying notifications
import { toast } from 'react-toastify';

const ChatPrivateUsersList = ({ onSelectUser, selectedUserId }) => {
    // State hook for storing user data
    const [users, setUsers] = useState([]);

    // Effect hook to fetch users when the component mounts
    useEffect(() => {
        // Define an async function to fetch users
        const fetchAllUsers = async () => {
            try {
                // Call fetchUsers method from UserService
                const response = await UserService.fetchUsers();
                // Update the users state with the fetched data
                setUsers(response.data.data.users);

                // Handle errors that occur during fetching
            } catch (error) {
                // Only log errors in development mode
                if (process.env.NODE_ENV === 'development') {
                    console.error('Failed to fetch users:', error);
                }
                // Display an error toast notification
                toast.error('Error fetching users. Please try again later.');
            }
        };

        // Invoke the fetch function
        fetchAllUsers();
    }, []); // Empty dependency array to run only once

    return (
        <div>
            <h2>List of all users</h2>

            {/* List to display the users */}
            <ul>
                {/* Map through the users state to generate list items */}
                {users.map((user) => (
                    <li
                        key={user._id}
                        onClick={() =>
                            onSelectUser(
                                user._id,
                                user.firstName + ' ' + user.lastName,
                            )
                        } // Set the selected user on click
                        style={{
                            fontWeight:
                                user._id === selectedUserId ? 'bold' : 'normal',
                        }} // Bold the font if the user is selected
                    >
                        {/* Display the full name of the user */}
                        {user.firstName} {user.lastName}
                    </li>
                ))}
            </ul>
        </div>
    );
};

// Export 'ChatPrivateUsersList' for accessibility throughout the application
export default ChatPrivateUsersList;
