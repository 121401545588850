/**
 * @module Selectors
 * @description Provides selector functions to derive state from the Redux store efficiently.
 * These selectors utilize the reselect library to compute derived data, allowing React components
 * to get only the necessary state.
 */

// Import createSelector from reselect to create memoized selector functions
import { createSelector } from 'reselect';

// Define a selector to get the messages state from the Redux store
const getMessagesState = (state) => state.messagesStore;

// Define a selector to get the user state from the Redux store
const getUserState = (state) => state.userStore;

/**
 * Create a memoized selector to retrieve private messages from the messages state.
 * @returns {Array} Returns the list of private messages.
 * @type {Function}
 */
export const privateMessagesSelector = createSelector(
    getMessagesState,
    (messagesState) => messagesState.privateMessages,
);

/**
 * Create a memoized selector to retrieve the current user from the user state.
 * @returns {Object} Returns the user object.
 * @type {Function}
 */
export const userSelector = createSelector(
    getUserState,
    (userState) => userState.user,
);

/**
 * Create a memoized selector to retrieve the loading status from the messages state.
 * @returns {boolean} Returns the loading boolean.
 * @type {Function}
 */
export const loadingSelector = createSelector(
    getMessagesState,
    (messagesState) => messagesState.loading,
);

/**
 * Create a memoized selector to retrieve any error messages from the messages state.
 * @returns {string|null} Returns the error message string or null if no error.
 * @type {Function}
 */
export const errorSelector = createSelector(
    getMessagesState,
    (messagesState) => messagesState.error,
);
