/**
 * Redux Store
 *
 * Configured Redux store for managing the application state.
 * The store includes slices for user-related, post-related, and message-related state management.
 * Provides a centralized data store for storing and updating the application state throughout the entire application.
 * Logger middleware is conditionally included during development to aid debugging by logging actions and the resulting state changes.
 *
 * @type {Object}
 * @exports {Object} store - Configured Redux store for application-wide state management.
 */

// Import the configureStore function from Redux Toolkit for setting up the Redux store
import { configureStore } from '@reduxjs/toolkit';

// Import the 'userSlice' reducer from the userSlice file to manage user-related state and actions in the Redux store
import userSlice from './userSlice';

// Import the 'postsSlice' reducer from the postsSlice file to manage post-related state and actions in the Redux store
import postsSlice from './postsSlice';

// Import the 'messagesSlice' reducer from the messagesSlice file to manage message-related state and actions in the Redux store
import messagesSlice from './messagesSlice';

// Import logger from 'redux-logger' to enable logging of Redux state changes and actions for easier debugging
import logger from 'redux-logger';

// Create the Redux store with 'userSlice', 'postsSlice', and 'messagesSlice' as reducers to manage the application state
const store = configureStore({
    reducer: {
        userStore: userSlice, // userStore slice: Manages user-related state and actions using the userSlice reducer
        postsStore: postsSlice, // postsStore slice: Manages post-related state and actions using the postsSlice reducer
        messagesStore: messagesSlice, // messagesStore slice: Manages message-related state and actions using the messagesSlice reducer
    },
    // Append 'logger' middleware to default middleware to log every action and the next state during development
    middleware: (getDefaultMiddleware) =>
        process.env.NODE_ENV === 'development'
            ? getDefaultMiddleware().concat(logger)
            : getDefaultMiddleware(),
});

// Export the configured Redux store for accessibility throughout the application
export default store;
