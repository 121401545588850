// Import axios for HTTP request functionality with RESTful APIs
import axios from 'axios';

/**
 * MessagesService module
 *
 * This module provides static methods for message management tasks,
 * including sending messages, fetching all messages, and fetching private messages between users.
 * It uses axios for HTTP requests to interact with the message-related endpoints of the backend.
 */
class MessagesService {
    /**
     * Sends a new message to the server.
     * This method performs a POST request to the `/messages/addMessage` endpoint, including the message data in the request body.
     * It is used to send a new message, either public or private, depending on the specified parameters.
     *
     * @param {Object} body - The content and details of the message to send.
     * @returns {Promise} A promise that resolves with the response after sending the message.
     */
    static sendMessage = (body) => axios.post('/messages/addMessage', body);

    /**
     * Fetches all messages from the server.
     * This method performs a GET request to the `/messages` endpoint.
     * It is used to retrieve all messages, both public and private, that the logged-in user has access to view.
     *
     * @returns {Promise} A promise that resolves with the response containing all accessible messages.
     */
    static fetchMessages = () => axios.get('/messages');

    /**
     * Fetches private messages between the logged-in user and another specified user.
     * This method performs a GET request to the `/messages/private/${userId}` endpoint, including the user ID in the URL.
     * It is used to retrieve all private messages exchanged between the logged-in user and the specified user.
     *
     * @param {string} userId - The ID of the other user involved in the private conversation.
     * @returns {Promise} A promise that resolves with the response containing the private messages.
     */
    static fetchPrivateMessages = (userId) =>
        axios.get(`/messages/private/${userId}`);
}

// Export MessagesService for accessibility throughout the application
export default MessagesService;
