/**
 * ChatPublic Component
 *
 * This component handles the public chat functionality of the application.
 * It allows users to view public messages, send new messages, and provides real-time interaction using WebSockets.
 * It leverages Redux for state management and communicates with the backend via services to fetch and send messages.
 *
 * @returns {JSX.Element} Renders the public chat interface including message display and a form to send new messages.
 * @type {React.FunctionComponent}
 */

// Import 'useEffect' for lifecycle management and 'useState' for state management within the component
import { useState, useEffect } from 'react';

// Import hooks from 'react-redux' to interact with the Redux state
import { useSelector, useDispatch } from 'react-redux';

// Import function to send messages through WebSockets
import { sendSocketMessage } from '../../services/socketService';

// Import UUID to generate unique identifiers for new messages
import { v4 as uuidv4 } from 'uuid';

// Import actions for handling public messages within the Redux store
import {
    fetchMessagesStart, // Action to initiate fetching of messages
    fetchPublicMessagesSuccess, // Action to handle successfully fetched public messages
    fetchMessagesFailure, // Action to handle errors in fetching messages
    publicMessageReceived, // Action to handle reception of new public messages via WebSocket
} from '../../store/messagesSlice';

// Import service for making API requests related to messages
import MessagesService from '../../services/messagesService';

const ChatPublic = () => {
    // Initialize the dispatch function to enable the dispatching of actions to the Redux store
    const dispatch = useDispatch();

    // Access publicMessages, loading, and error states from the Redux store
    const { publicMessages, loading, error } = useSelector(
        (state) => state.messagesStore,
    );

    // State to store user input for sending messages
    const [input, setInput] = useState('');

    // Effect hook to fetch public messages on component mount
    useEffect(() => {
        const fetchPublicMessages = async () => {
            // Dispatch action to indicate start of message fetching
            dispatch(fetchMessagesStart());
            try {
                const response = await MessagesService.fetchMessages(); // Fetch all messages
                const messages = response.data.data || []; // Default to empty array if no data
                const publicMessages = messages.filter((msg) => msg.isPublic); // Filter public messages
                dispatch(fetchPublicMessagesSuccess(publicMessages)); // Dispatch successful fetch action
            } catch (err) {
                dispatch(fetchMessagesFailure(err.toString())); // Dispatch failure action if error occurs
            }
        };
        // Call the fetchMessages function to execute the fetching process
        fetchPublicMessages();
    }, [dispatch]); // Depend on dispatch to prevent re-creation of function

    // Function to handle message sending
    const sendMessage = () => {
        // Check if input is not just whitespace
        if (input.trim()) {
            const clientMessageId = uuidv4(); // Generate unique ID for the new message
            sendSocketMessage(input, true, null, clientMessageId); // Send message via WebSocket
            setInput(''); // Clear the input after sending
        }
    };

    // Handler for the Enter key in the input field
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent default action (form submission or newline)
            sendMessage(); // Send the message when Enter key is pressed
        }
    };

    return (
        <div>
            <h2>Public Chat</h2>
            <div>
                {/* Display loading message */}
                {loading && <p>Loading messages...</p>}

                {/* Display error message if any */}
                {error && <p>Error fetching messages: {error}</p>}

                {/* Map through messages and display each */}
                {publicMessages.map((msg, index) => (
                    <p key={msg._id || index}>{msg.message}</p>
                ))}
            </div>
            <input
                value={input}
                onChange={(e) => setInput(e.target.value)} // Update state with input value
                onKeyDown={handleKeyDown} // Handle key down events
                className="border-2 border-gray-200"
            />
            {/* Button to send messages*/}
            <button onClick={sendMessage}>Send</button>
        </div>
    );
};

// Export 'ChatPublic' component for accessibility throughout the application
export default ChatPublic;
