/**
 * @module SocketService
 * @description This module configures the socket client for real-time communication with the server,
 * handles connection events, and dispatches actions to the Redux store based on incoming socket messages.
 */

// Import socket.io-client to enable real-time communication via WebSockets
import io from 'socket.io-client';

// Import the store to dispatch actions based on socket events
import store from '../store/store';

// Import specific actions to handle message reception events
import {
    publicMessageReceived, // Dispatch actions when public messages are received via socket
    privateMessageReceived, // Dispatch actions when private messages are received via socket
} from '../store/messagesSlice';

// Retrieve token from local storage for socket authentication
const token = localStorage.getItem('sm_token');

// Connect to the socket server using the provided URL and token for authentication
const socket = io(process.env.REACT_APP_SOCKET_URL, {
    reconnection: false, // Disable automatic reconnection to the server after a disconnect
    query: { token }, // Pass the authentication token as part of the connection query
});

// Monitor and log the socket connection event to the server, displaying the socket ID when in development mode
socket.on('connect', () => {
    if (process.env.NODE_ENV === 'development') {
        console.log(`Connected to server with socket ID: ${socket.id}`);
    }
});

// Handle disconnection events from the socket server, logging the reason if in development mode
socket.on('disconnect', (reason) => {
    if (process.env.NODE_ENV === 'development') {
        console.log(`Disconnected from server. Reason: ${reason}`);
    }
});

// Listen for 'publicMessageReceived' events from the server and process incoming public messages
socket.on('publicMessageReceived', (message) => {
    // Retrieve the current state from the Redux store
    const currentState = store.getState();

    // Check if the message already exists to avoid duplicates
    const existingMessage = currentState.messagesStore.publicMessages.find(
        (msg) =>
            msg.clientMessageId &&
            msg.clientMessageId === message.clientMessageId,
    );

    // Dispatch the publicMessageReceived action if it's a new message
    if (!existingMessage && message.isPublic) {
        store.dispatch(publicMessageReceived(message));
    }
});

// Listen for 'privateMessageReceived' events from the server and handle incoming private messages
socket.on('privateMessageReceived', (message) => {
    // Retrieve the current state from the Redux store
    const currentState = store.getState();

    // Check if the message already exists to avoid duplicates
    const existingMessage = currentState.messagesStore.privateMessages.find(
        (msg) =>
            msg.clientMessageId &&
            msg.clientMessageId === message.clientMessageId,
    );

    // Dispatch the privateMessageReceived action if it's a new message
    if (!existingMessage && !message.isPublic) {
        store.dispatch(privateMessageReceived(message));
    }
});

/**
 * Sends a message through the socket connection.
 * @type {Function}
 * @param {string} messageContent - The content of the message to send.
 * @param {boolean} isPublic - Indicates whether the message is public.
 * @param {string|null} receiverId - The ID of the message recipient, if private.
 * @param {string} clientMessageId - Client-side unique identifier for the message.
 * @return {void} Emits a 'sendMessage' event to the server with the message data.
 */
export const sendSocketMessage = (
    messageContent,
    isPublic = true,
    receiverId = null,
    clientMessageId,
) => {
    // Construct the message data including optional receiverId
    const messageData = {
        message: messageContent,
        isPublic,
        clientMessageId,
        ...(receiverId && { receiverId }),
    };
    // Emit the sendMessage event to the server with the message data
    socket.emit('sendMessage', messageData);
};

// Export the socket instance for accessibility throughout the application
export default socket;
